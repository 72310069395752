import React from 'react'
import { Link } from 'gatsby'

import '../styles/faq.scss'

export default ({ what }) => {
  return (
    <div className='section'>
      <h1>FAQ</h1>
      <div class='faqGrid'>
        <div>
          <b>If I'm not happy, can I cancel my subscription?</b>
          <p>
            Of course, cancel anytime yourself from your dashboard if you are
            not satisfied. If you cancel before your trial ends you won't be
            billed.
          </p>
        </div>
        <div>
          <b>Is my data safe with Nucleus?</b>
          <p>
            Communications between your app and Nucleus are done with <i>SSL</i>
            . You can <a href='mailto:hello@nucleus.sh'>ask us anytime</a> that
            all your data be erased from our servers. We run off-site backups of
            your data every day.
          </p>
        </div>
        <div>
          <b>What data is collected?</b>
          <p>
            We list all the data collected{' '}
            <Link to='/transparency'>on this page</Link>. We only collect the
            necessary data and only use it to provide you with analytics.
          </p>
        </div>
        <div>
          <b>Can I export my data?</b>
          <p>
            Yes, all your data can be exported via the API or downloaded as CSV
            from the dashboard.
          </p>
        </div>

        {what !== 'pricing' && (
          <div>
            <b>Does tracking continue offline?</b>
            <p>
              <b>Yes.</b> Using the modules, events and bugs data are cached on
              the client while waiting for an internet connection. As soon as
              the client connects, data is synced.
            </p>
          </div>
        )}
        {['javascript', 'electron'].includes(what) && (
          <div>
            <b>How are bugs detected and reported?</b>
            <p>
              You don't need to do anything, requiring the{' '}
              <b>nucleus-analytics</b> module will automatically set it up.
              Internally, we use Node process events (<i>uncaughtException</i>{' '}
              and <i>unhandledRejection</i>).
            </p>
          </div>
        )}
        {what === 'pricing' && (
          <div>
            <b>Do you offer any discounts?</b>
            <p>
              We offer a 70% discount to students, non-profits organizations and
              open-source projects. Contact us to apply.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

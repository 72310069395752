import React, { useState, useEffect } from 'react'
// import { Link, useStaticQuery, graphql } from 'gatsby' //'Link' is defined but never used. //'useStaticQuery' is defined but never used. //'graphql' is defined but never used.
// import Img from 'gatsby-image' //'Img' is defined but never used.

import Layout from '../components/layout'
import Seo from '../components/seo'
import Faq from '../components/faq'
import Cta from '../components/cta'

import '../styles/pricing.scss'

import logo1 from '../../content/assets/customers/goarthur.ai.svg'
import logo2 from '../../content/assets/customers/aibod.png'
import logo3 from '../../content/assets/customers/runway.svg'
// import logo4 from "../../content/assets/customers/inedit.com.svg"
import logo5 from '../../content/assets/customers/nolimit.png'
import logo6 from '../../content/assets/customers/criptext.svg'
// import logo7 from "../../content/assets/customers/cadmium.png"
import logo8 from '../../content/assets/customers/wooting.png'
import stripeClimate from '../../content/assets/StripeClimate.svg'

function formatter (num) {
  return num > 999999 ? parseFloat((Math.abs(num) / 1e6).toFixed(1)) + 'M' : (num > 999 ? parseFloat((Math.abs(num) / 1e3).toFixed(1)) + 'K' : num)
}

const Pricing = ({ data, location }) => {
  const [mteX, setMteX] = useState(0)
  const [users, setUsers] = useState(500)
  const [price, setPrice] = useState(0)
  // const [rate, setRate] = useState(0) //'rate' is assigned a value but never used. //'setRate' is assigned a value but never used.
  const [plan, setPlan] = useState('Starter')

  useEffect(() => {
    const totalUsers = parseInt(Math.pow(1.04, mteX).toPrecision(2) * 100)
    setUsers(totalUsers)

    let total = 0

    if (totalUsers < 10000) {
      setPlan('Starter')
      const billedUsers = totalUsers - 1000
      const extra = Math.ceil(billedUsers / 1000) * 20
      total = 49 + extra
    } else if (totalUsers < 50000) {
      setPlan('Growth')
      const billedUsers = totalUsers - 10000
      const extra = Math.ceil(billedUsers / 10000) * 100
      total = 199 + extra
    } else {
      setPlan('Enterprise')
      setPrice(null)
    }

    setPrice(total)
  })

  return (
    <Layout current='pricing'>
      <Seo title='Pricing' />
      <div class='section pricing'>
        <h1 class='lean'><b>Understand</b> your users' journey.</h1>
        <p class='subtitle'>Clear and affordable pricing that scales as you grow.<br />Scroll down for a features breakdown.</p>
        <div class='plans'>
          <div class={`card ${plan !== 'Starter' ? 'flat' : ''}`}>
            <h3>Starter</h3>
            <div class='perks'>
              <p>1,000 active users</p>
              <p>+ $20 / additional 1,000 active users</p>
            </div>
            <p>$<span class='price'>49</span> / mo</p>
          </div>
          <div class={`card ${plan !== 'Growth' ? 'flat' : ''}`}>
            <h3>Growth</h3>
            <div class='perks'>
              <p>10,000 active users</p>
              <p>+ $100 / additional 10,000 active users</p>
            </div>
            <p>$<span class='price'>199</span> / mo</p>
          </div>
          <div class={`card ${plan !== 'Enterprise' ? 'flat' : ''}`}>
            <h3>Enterprise</h3>
            <div class='perks'>
              <p>Unlimited users</p>
              <p>Dedicated account manager</p>
            </div>
            <p><a href='mailto:hello@nucleus.sh'>Contact Us</a></p>
          </div>
        </div>

        <div>
          <div class='calculator row'>
            <div class='col'>
              <p><b>What is an active user?</b></p>
              <p>Any user that opened or performed an action in your app in the past 30 days (Monthly Active User).</p>
            </div>
            <div class='col'>
              <span>Estimate your spend: <br /></span>
              <span><b>{formatter(users)}</b> monthly active users</span><br />
              <input type='range' value={mteX} onChange={e => setMteX(e.target.value)} min='0.9' max='200' />
              <span>{price ? <span>$<span class='price'>{parseInt(price)}</span>/mo</span> : <span>Contact Us</span>}</span>
            </div>
          </div>
          <p><a class='primary btn' href='https://dash.nucleus.sh/signup'>Start your Free Trial →</a></p>
          <small>Free for the first 30 days.</small>
        </div>

        <div class='climate'>

          <img src={stripeClimate} /><br />
          <b>Stripe Climate Partner</b><br />
          <span>1% of your Nucleus subscription will contribute to removing CO₂ from the atmosphere.</span>

        </div>

        <div id='features' class='section'>
          <p><b>Features breakdown</b></p>
          <div class='features'>
            <table>
              <tr>
                <th />
                <th>Starter</th>
                <th>Growth</th>
                <th>Enterprise</th>
              </tr>
              <tr>
                <td>Monthly Active Users (base)</td>
                <td>1,000</td>
                <td>10,000</td>
                <td>Custom</td>
              </tr>
              <tr>
                <td>Tracked apps</td>
                <td>∞</td>
                <td>∞</td>
                <td>∞</td>
              </tr>
              <tr>
                <td>Team members</td>
                <td>1</td>
                <td>5</td>
                <td>∞</td>
              </tr>
              <tr>
                <td>Data retention</td>
                <td>1 year</td>
                <td>2 years</td>
                <td>∞</td>
              </tr>
              <tr class='separator'>
                <td>Platform Features</td>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>App analytics</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Automatic bug tracking</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>API + integration modules</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Export your data (API or CSV)</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Live view</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>User explorer</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Automatic error alerts</td>
                <td />
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Advanced analytics: cohort, funnels, usage flow</td>
                <td />
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>White-labeling</td>
                <td />
                <td />
                <td class='check'>✓</td>
              </tr>
              <tr class='separator'>
                <td>Compliance</td>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>CCPA</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>GDPR</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Pay by invoice</td>
                <td />
                <td />
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Security review</td>
                <td />
                <td />
                <td class='check'>✓</td>
              </tr>
              <tr class='separator'>
                <td>Support</td>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>Chat & email</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td />
                <td class='check'>✓</td>
                <td class='check'>✓</td>
              </tr>
              <tr>
                <td>Account manager</td>
                <td />
                <td />
                <td class='check'>✓</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <Faq what='pricing' />
      <Cta />
    </Layout>
  )
}

export default Pricing
